<template>
	<div class="table">
		<el-table
			border
			ref="multipleTable"
			id="exportTab"
			:data="tableData"
			:header-cell-style="{ 'text-align': 'center' }"
			height="60vh"
			:cell-style="{ 'text-align': 'center' }"
			tooltip-effect="dark"
			style="width: 100%"
		>
			<el-table-column prop="date" label="日期"> </el-table-column>
			<el-table-column prop="pv" label="PV" min-width="15%"> </el-table-column>
			<el-table-column prop="uv" label="UV" min-width="15%"> </el-table-column>
			<el-table-column prop="wz_count" label="文章数量" min-width="15%">
			</el-table-column>
			<el-table-column prop="kx_count" label="快讯数量" min-width="15%">
			</el-table-column>
			<el-table-column prop="zl_count" label="专栏数量" min-width="15%">
			</el-table-column>
			<el-table-column prop="deviceCount" label="新增设备数量" min-width="15%">
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
export default {
	props: ["tableData"],
	name: "",
	data() {
		return {
			value: true,
		};
	},
	methods: {},
};
</script>

<style scoped></style>
